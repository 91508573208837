import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { getTransactionAction } from '../../../store/actions/transactionActions';
import { useDispatch, useSelector } from 'react-redux';
import { getRemittanceDetailStatus } from '../../../utils/get-transaction-status';
import { formatCurrency } from '../../../utils/numbers';
import { RemittanceSkeleton } from '../../../components/skeleton/WidgetSkeleton';

const RemittanceTransactionDetails = ({ transactionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
    dispatch(getTransactionAction(transactionId));
  };
  const hideDetails = () => {
    setOpenDetails(false);
  };
  const { getTransaction, getTransactionLoading, getTransactionError } =
    useSelector((state) => state.transaction);
  return (
    <>
      <div
        className="text-blue-link font-bold cursor-pointer"
        onClick={showDetails}
      >
        View Details
      </div>
      <Drawer
        title={t('remittance_list.details_modal.title')}
        onClose={hideDetails}
        visible={openDetails}
        contentWrapperStyle={{ width: 800 }}
        className="remmitance-drawer"
      >
        {getTransactionError ? (
          <div>Failed to get transaction</div>
        ) : getTransactionLoading ? (
          <div className="grid grid-cols-1 justify-items-center text-center">
            <RemittanceSkeleton />
          </div>
        ) : (
          <>
            <div className="mb-6 flex flex-col lg:flex-row lg:space-x-5">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="border border-grey-300 rounded text-center p-6 flex flex-col h-full justify-center">
                  {getRemittanceDetailStatus(getTransaction?.status)}
                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.title')}
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.sender_user?.name}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.mobile')}
                    </div>
                    <div className="text-base font-bold">
                      +{getTransaction?.sender_user?.mobile_country}{' '}
                      {getTransaction?.sender_user?.mobile}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.account')}
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.remittance?.beneficiary?.account_number}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.bank')}
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.remittance?.beneficiary?.bank_name}
                    </div>
                  </div>

                  <div className="">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.bank_code')}
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.remittance?.beneficiary?.bank_code ||
                        '-'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="border border-grey-300 rounded text-center p-6 flex flex-col h-full justify-center">
                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.transferred')}
                    </div>
                    <div className="text-2xl font-bold">
                      SGD{' '}
                      <span className="text-green-anak">
                        {formatCurrency(getTransaction?.amount)}
                      </span>
                    </div>
                  </div>
                  {/* <div className="mb-4">
                <div className="text-grey-600">
                  {t('remittance_list.details_modal.received')}
                </div>
                <div className="text-2xl font-bold">
                  IDR <span className="text-green-anak">13,723.00</span>
                </div>
              </div> */}
                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.fees')}
                    </div>
                    <div className="text-2xl font-bold">
                      SGD{' '}
                      <span className="text-green-anak">
                        {formatCurrency(getTransaction?.remittance?.fee)}
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.total_debited')}
                    </div>
                    <div className="text-2xl font-bold">
                      SGD{' '}
                      <span className="text-green-anak">
                        {formatCurrency(getTransaction?.amount)}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-grey-600">Transaction ID</div>
                    <div className="text-base font-bold">
                      {getTransaction?.remittance?.remittance?.response_data
                        ?.system_reference_number || '-'}
                    </div>
                  </div>
                  <div className="">
                    <div className="text-grey-600">
                      {t('remittance_list.details_modal.remark')}
                    </div>
                    <div className="text-base font-bold">
                      {getTransaction?.remittance?.comments || '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border border-grey-300 rounded p-4">
              <div className="text-xl font-bold mb-4">
                {t('remittance_list.details_modal.debited_title')}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-flow-col-1 gap-4 space-y-0">
                <div className="border-r-0 border-b lg:border-r lg:border-b-0 pb-4">
                  <div className="text-grey-600">
                    {t('remittance_list.details_modal.paid_to')}
                  </div>
                  <div className="text-base font-bold">
                    {getTransaction?.remittance?.beneficiary?.bank_name}
                    <span className="block text-sm">
                      Ac No. -{' '}
                      {getTransaction?.remittance?.beneficiary?.account_number}
                    </span>
                  </div>
                </div>
                <div className="border-r-0 border-b lg:border-r lg:border-b-0 pb-4">
                  <div className="text-grey-600">
                    {t('remittance_list.details_modal.from')}
                  </div>
                  <div className="text-base font-bold">
                    {getTransaction?.sender_user?.name}
                    <span className="block text-sm">
                      {getTransaction?.sender_user?.mobile}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="text-grey-600">
                    {t('remittance_list.details_modal.transaction_id')}
                  </div>
                  <div className="text-base font-bold">
                    {getTransaction?.remittance?.uuid || '-'}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default RemittanceTransactionDetails;
