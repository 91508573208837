import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiInfoCircle } from 'react-icons/bi';
import { Tooltip, Card } from 'antd';
import UserDetails from '../../components/dashboard/user-details';
import Section from '../../components/dashboard/section';
import WalletSummary from './wallet-summary';
import WidgetTitle from '../../components/dashboard/widget-title';
import { getColor } from '../../utils/colors';
import { Column } from '@ant-design/charts';
import { useTranslation } from 'react-i18next';
import WalletDetailsTable from './wallet-table';
import { useDispatch, useSelector } from 'react-redux';
import { WalletWidgetSkeleton } from '../../components/skeleton/WidgetSkeleton';
import BarChartSkeleton from '../../components/skeleton/BarChartSkeleton';
import moment from 'moment';
import { getWalletSummaryAction } from '../../store/actions/walletActions';
// import { formatCurrency } from '../../utils/numbers';

function WalletDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let title = t('wallet_details.section_title');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Wallet',
      url: '/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];
  const { walletSummary, walletSummaryLoading } = useSelector(
    (state) => state.wallet
  );
  const inflow_transaction_by_month = walletSummary?.transaction_by_month.map(
    (item) => {
      return {
        name: 'Amount Inflow',
        month: moment(item.date).format('MMM YYYY'),
        number: item.inflow,
      };
    }
  );
  const outflow_transaction_by_month = walletSummary?.transaction_by_month.map(
    (item) => {
      return {
        name: 'Amount Outflow',
        month: moment(item.date).format('MMM YYYY'),
        number: item.outflow,
      };
    }
  );
  const transactionChartConfig = {
    data:
      inflow_transaction_by_month?.concat(outflow_transaction_by_month) || [],
    isGroup: true,
    xField: 'month',
    yField: 'number',
    seriesField: 'name',
    color: [getColor('bg-green-anak'), getColor('bg-green-100')],
    legend: false,
    height: 300,
  };
  useEffect(() => {
    dispatch(getWalletSummaryAction());
  }, []); // eslint-disable-line

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col w-full mb-6">
        <WidgetTitle>
          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
            {/*wallet widget*/}
            <div className="flex flex-col justify-between w-full lg:w-1/3">
              {walletSummaryLoading ? (
                <>
                  <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4 mt-4">
                    <WalletWidgetSkeleton />
                  </div>
                  <div className="rounded-lg bg-white border border-grey-100 flex flex-col items-center py-5 px-4 mt-4">
                    <WalletWidgetSkeleton />
                  </div>
                </>
              ) : (
                <>
                  <WalletSummary data={walletSummary} />
                  <UserDetails user_details={walletSummary} />
                </>
              )}
            </div>
            {/* TRANSACTION BY MONTH */}
            <div className="flex flex-col justify-between w-full lg:w-2/3">
              {walletSummaryLoading ? (
                <BarChartSkeleton />
              ) : (
                <Section
                  title="Transaction by Month"
                  middle="green"
                  label={{ one: 'Amount Inflow', two: 'Amount Outflow' }}
                  right={
                    <Tooltip
                      title="Topups vs Remittance"
                      placement="topRight"
                      arrowPointAtCenter
                    >
                      <BiInfoCircle size={16} />
                    </Tooltip>
                  }
                  className="hidden md:inline-block"
                >
                  <Column {...transactionChartConfig} />
                </Section>
              )}
            </div>
          </div>
        </WidgetTitle>
      </div>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <WalletDetailsTable />
      </Card>
    </>
  );
}

export default WalletDetails;
