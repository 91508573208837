import React, { useState, useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { Button, Image, message, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from '../../utils/generic';
import { BiExport, BiDownload, BiTimeFive, BiEdit, BiErrorCircle } from 'react-icons/bi';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { utcToLocalDateTime } from '../../utils/dateFormat';
import { getIGWDetailsAction } from '../../store/actions/igwServiceActions';
import { IgwSkeleton } from '../../components/skeleton/WidgetSkeleton';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axiosClient from '../../services/config';
import { getHTMLToPDFString } from '../../utils/getHTMLToPDFString';
// import JSZip from "jszip";
// import { saveAs } from 'file-saver';
// import JSZipUtils from '../../utils/JsZipUtils';
// let zip = new JSZip();
// let folder = zip.folder('collection');

function IGWServicesDetails() {
  const { subscriber_id, form_uuid } = useParams();

  const initialTableParams = {
    subscriber_id: subscriber_id,
    form_uuid: form_uuid,
  };
  let title = 'View IGW Details';
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Customer Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: 'IGW',
      url: '/customers/igw/',
      last: false,
    },
    { home: false, title, url: '/', last: true },
  ];

  // const inputRef = useRef(null);
  const currentDate = moment(new Date().toISOString()).format(
    'YYYY-MM-DD-HH:mm'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [imgBase64, setImageBase64] = useState('');

  const { currentIGWService, currentIGWServiceLoading } = useSelector(
    (state) => state.igwService
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIGWDetailsAction(initialTableParams));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


  const printDocument = async () => {
    setIsLoading(true)
    let HTMLToPDFString = getHTMLToPDFString(currentIGWService, imgBase64)

    let iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = HTMLToPDFString;
  
    let myframe = document.getElementsByTagName("iframe")[0];
    myframe.id = "newID";
    document.getElementById('newID').style.visibility="hidden"
      
    await html2canvas(iframedoc.body, {
      scale: 2,
      onrendered: function (canvas) {
        // document.body.appendChild(canvas);
        // document.body.removeChild(iframe);
      }
    }).then((canvas) => {

      const imgData = canvas.toDataURL('image/jpeg');
      let imgWidth = 420;
      let pageHeight = 609;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let pdf = new jsPDF('p', 'px', "a4", true);
      let position = 10;

      pdf.setFontSize(8);
      const pageSize = pdf.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

      let currentPage = 0;
      while (heightLeft >= 0) {
        if (heightLeft !== imgHeight) {
          position = heightLeft - imgHeight + 10;
          pdf.addPage();
        }
        currentPage++;
        pdf.addImage(imgData, 'JPEG', 10, position, imgWidth, imgHeight + 10);
        heightLeft -= pageHeight;
        let footer1 = `Exported On ${utcToLocalDateTime(new Date())}`
        let footer2 = `Bang Ando - Biodata - ${currentIGWService?.input_data?.legal_name}`
        pdf.text(footer1, 10, pdf.internal.pageSize.height - 10)
        pdf.text(footer2, pageWidth / 2 - 80, pdf.internal.pageSize.height - 10)
        const footer3 = `${currentPage}/${"2"}`;
        pdf.text(footer3, pageWidth - 20, pdf.internal.pageSize.height - 10)
      }

      pdf.save(`${currentIGWService?.input_data?.legal_name}-${currentDate}.pdf`);

      setIsLoading(false)
      notification['success']({
        message: 'Biodata Exported Successfully!'
      });

      document.body.removeChild(iframe);
    }).catch(error => {
      document.body.removeChild(iframe);
    })
  };

  // convert img url to img data url
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  toDataURL(currentIGWService?.input_data?.photo, function (dataUrl) {
    setImageBase64(dataUrl);
  });

  //export documents images as zip file
  // function exportImageToZip() {
  //   var count = 0;
  //   var urls = [
  //     [`${currentIGWService?.input_data?.photo}`, `${currentIGWService?.input_data?.legal_name}`],
  //     [`${currentIGWService?.input_data?.ktp_id_card_image}`, `KTP Card`],
  //     [`${currentIGWService?.input_data?.birth_certificate_image}`, `Birth certificate`],
  //     [`${currentIGWService?.input_data?.kk_family_card_image}`, `KK family card`],
  //   ];

  //   urls.forEach(function (url) {
  //     JSZipUtils.getBinaryContent(url[0], function (err, data) {
  //       if (err) {
  //         message.error('Something went wrong');
  //         throw err;
  //       }
  //       folder.file(url[1], data, { binary: true });
  //       count++;
  //       if (count === urls.length) {
  //         zip.generateAsync({ type: 'blob' }).then(function (content) {
  //           saveAs(content, `${currentIGWService?.input_data?.legal_name}.zip`);
  //         });
  //       }
  //     });
  //   });
  // }

  function exportImageToZip() {
    const downloadZipServiceAPI = async () => {
      return await axiosClient.get(`/organisations/${process.env.REACT_APP_IGW_ORGANIZATION_UUID}/services/${process.env.REACT_APP_IGW_SERVICE_UUID}/subscriber/${subscriber_id}/form/${form_uuid}/download-zip/`);
    };

    downloadZipServiceAPI()
      .then(() => {
        message.success("File Sent Successfully")
      })
      .catch(() => {
        message.error("Something went wrong!")
      })
  }

  return (
    <>
      <SectionTitle
        title={
          <div className="flex items-center">
            {title}
          </div>
        }
        right={<Breadcrumb items={breadcrumbsItem} />}
      />

      <div className="w-full" >
        {currentIGWServiceLoading ? (
          <>
            <IgwSkeleton />
          </>
        ) : (
          <div className="flex flex-col">
            {currentIGWService?.status === "OLD_VERSION" ?
              <>
                <div className="grid grid-cols-12 gap-4 mb-5 row-auto w-full rounded-lg bg-white p-5">
                  <div className="col-span-12 sm:col-span-4 pt-0 rounded-lg">
                    <div className="flex w-full mb-2 text-lg items-center">
                      <BiErrorCircle className="mr-2 text-red-500 text-bold" size={20} /> Please note that this is an older version
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-8 rounded-lg flex flex-col md:flex-row items-center justify-end">
                    <div className="flex flex-row justify-between">
                      <Link to={`/customers/igw/subscriber/${subscriber_id}`}>
                        <Button
                          className="ml-2 flex items-center text-green-500 border border-green-500"
                          type="default"
                          size="default"
                          icon={<BiTimeFive className="mr-2" size={16} />}
                        >
                          View History
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4 mb-5 row-auto w-full rounded-lg bg-white p-5">
                  <div className="col-span-12 sm:col-span-4 pt-0 rounded-lg">
                    <div className="flex w-full mb-2">
                      <div className="w-1/2">Last Updated On:</div>
                      <div className="w-1/2 font-bold">{utcToLocalDateTime(currentIGWService?.reviewed_at)}</div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Updated By:</div>
                      <div className="w-1/2 font-bold">{currentIGWService?.reviewed_by}</div>
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-8 rounded-lg flex flex-col md:flex-row items-center justify-end">
                    <div className="flex flex-row justify-between">
                      <Button
                        className="ml-1 flex items-center"
                        type="success"
                        size="default"
                        loading={isLoading}
                        onClick={printDocument}
                        icon={<BiExport className="mr-2" size={16} />}
                      >
                        Export as Biodata
                      </Button>
                      <Button
                        className="ml-2 flex items-center"
                        type="success"
                        size="default"
                        onClick={exportImageToZip}
                        download
                        icon={<BiDownload className="mr-2" size={16} />}
                      >
                        Export Files
                      </Button>
                    </div>
                  </div>
                </div>
              </> :
              <div className="grid grid-cols-12 gap-4 mb-5 row-auto w-full rounded-lg bg-white p-5">
                <div className="col-span-12 sm:col-span-4 pt-0 rounded-lg">
                  <div className="flex w-full mb-2">
                    <div className="w-1/2">Last Updated On:</div>
                    <div className="w-1/2 font-bold">{utcToLocalDateTime(currentIGWService?.reviewed_at)}</div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-1/2">Updated By:</div>
                    <div className="w-1/2 font-bold">{currentIGWService?.reviewed_by}</div>
                  </div>
                </div>
                <div className="col-span-12 sm:col-span-8 rounded-lg flex flex-col md:flex-row items-center justify-end">
                  <div className="flex flex-row justify-between">
                    <Button
                      className="ml-1 flex items-center"
                      type="success"
                      size="default"
                      loading={isLoading}
                      onClick={printDocument}
                      icon={<BiExport className="mr-2" size={16} />}
                    >
                      Export as Biodata
                    </Button>
                    <Button
                      className="ml-2 flex items-center"
                      type="success"
                      size="default"
                      onClick={exportImageToZip}
                      icon={<BiDownload className="mr-2" size={16} />}
                    >
                      Export Files
                    </Button>
                  </div>
                  <div className="flex flex-row justify-between">
                    <Link to={`/customers/igw/subscriber/${subscriber_id}/form/${form_uuid}/review`}>
                      <Button
                        className="ml-2 flex items-center text-green-500 border border-green-500"
                        type="default"
                        size="default"
                        icon={<BiEdit className="mr-2" size={16} />}
                      >
                        Edit Details
                      </Button>
                    </Link>
                    <Link to={`/customers/igw/subscriber/${subscriber_id}`}>
                      <Button
                        className="ml-2 flex items-center text-green-500 border border-green-500"
                        type="default"
                        size="default"
                        icon={<BiTimeFive className="mr-2" size={16} />}
                      >
                        View History
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            }

            <div className="grid grid-cols-12 gap-4 mb-4 row-auto w-full">   
              <div className="col-span-12 md:col-span-8 pt-0 rounded-lg">
                <div className="text-xl font-bold bg-green-200 p-3 px-4 rounded-t-md">
                  Internal Comments ({currentIGWService?.comments?.length})
                </div>
                {currentIGWService?.comments?.length === 0 || currentIGWService?.comments === null ?
                  <div className="col-span-12 bg-white mb-5">
                    <div className="flex justify center items-center font-bold bg-green-50 border-b border-grey-300 p-5">No Comments Found</div>
                  </div> :
                  <div className="col-span-12 bg-white mb-5" id="expand">
                    {currentIGWService?.comments?.map((comment, index) => {
                      return (
                        <div key={index} className="flex justify items-center font-bold bg-green-50 border-b border-grey-300 p-5">
                          <img style={{ height: "50px", objectFit: "cover" }} className="rounded-full mr-4 border border-grey-300 " src={comment.photo} width="50px" alt="" />
                          <div className="flex flex-col">
                            <div className="text-md font-medium mb-1 flex sm:flex-row flex-col">
                              <p className="mr-5">{comment.name}</p>
                              <p className="text-xs text-grey-500">{utcToLocalDateTime(comment.reviewed_at)}</p>
                            </div>
                            {comment.comment || "-"}
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>}
                {/* <div className='font-bold text-lg text-center border border-green-200 p-3 mb-5' onClick={()=>{
                    if(document.getElementById("expand").style.height !== "fit-content")
                    document.getElementById("expand").style.height = "fit-content";
                    else
                    document.getElementById("expand").style.height = "100px";
                    
                  }}>Show More</div> */}
                <div className="text-xl font-bold bg-grey-200 p-3 px-4 rounded-t-md">
                  Step 1 - Personal Particulars
                </div>
                <div className="col-span-12 bg-white">
                  <div className="list mb-5">
                    <div className="flex w-full">
                      <div className="w-1/2">Legal Name</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.legal_name || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Mobile Number</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.mobile_number || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Email (Optional)</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.email || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Residential Address</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.residential_address || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Agent Code (Optional)</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.agent_code || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Date of Birth</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.date_of_birth || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Place of Birth</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.place_of_birth || '-'}
                      </div>
                    </div>
                    <div className="flex w-full ">
                      <div className="w-1/2">Nationality</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.nationality || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Ethnicity</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.ethnicity || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Martial Status</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.martial_status || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Religion</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.religion || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Gender</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.gender || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Hobbies</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.hobbies || '-'}
                      </div>
                    </div>
                    <div className="flex w-full ">
                      <div className="w-1/2">Identity card (KTP) No.</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.ktp_id_card.match(/.{1,4}/g).join(" ") || '-'}
                      </div>
                    </div>
                    <div className="flex w-full ">
                      <div className="w-1/2">Birth Certificate No.</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.birth_certificate_number ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Family Card (KK) No.</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.kk_family_card.match(/.{1,4}/g).join(" ") || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Do you have a passport?</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.passport_status || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Passport Number</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.passport_number || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Date of expiry</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.passport_date_of_expiry || '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xl font-bold bg-grey-200 p-3 px-4 rounded-t-md">
                  Step 2 - Working Experience
                </div>
                <div className="col-span-12 bg-white">
                  <div className="list mb-4">
                    <div className="flex w-full">
                      <div className="w-1/2">Experience Sector</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.experience_sector || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Additional Skills (Optional)</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.additional_skills || '-'}
                      </div>
                    </div>

                    <div className="flex w-full">
                      <div className="w-1/2">Language ability</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.language_ability || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Highest level of education</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.highest_level_of_eduaction ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">School Name</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.school_name || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Upskilling Interest</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.upskilling_interest || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Local Working Experience</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.local_working_experience ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Have you worked overseas before?</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.worked_overseas_before || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Overseas Working Experience</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.overseas_working_experience ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Please elaborate further</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.overseas_working_description ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Do you have a Driver's License?</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.driving_license || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Driver License Class</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.driving_license_class ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-1/2">Driver License Country</div>
                      <div className="w-1/2">
                        {currentIGWService?.input_data?.driving_license_country ||
                          '-'}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="text-xl font-bold bg-grey-200 p-3 px-4 rounded-t-md">
                  Step 3 - Apply for Job Interest
                </div>
                <div className="col-span-12 bg-white">
                  <div className="list mb-4">
                    <div className="flex w-full">
                      <div className="w-2/3">Job Interest:</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.job_interest || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Can you sweep and mop the floor?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_sweep_and_mop_floor ||
                          '-'}
                      </div>
                    </div>

                    <div className="flex w-full">
                      <div className="w-2/3">Can you iron clothes?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_iron_clothes || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Can you cook?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_cook || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Please elaborate further.</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.cooking_description || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Are you willing to wash car and wash clothes by hand?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_wash_car_and_clothes_by_hand || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Do you know how to feed and bath the baby?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_feed_and_bath_baby || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Can you babysit and have experience in looking after
                        babies?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_babysit || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Are you willing to fetch children to and from school?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_fetch_children_to_and_from_school || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Are you willing to look after the ill, partially disabled
                        and/or elderly persons?
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_look_after_ill_or_old_or_disabled || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">
                        Any other information to add (Optional)
                      </div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.self_description || '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xl font-bold bg-grey-200 p-3 px-4 rounded-t-md">
                  Step 4 - Declaration
                </div>
                <div className="col-span-12 bg-white">
                  <div className="list mb-4">
                    <div className="flex w-full">
                      <div className="w-2/3">Do you have your family’s consent?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.have_family_consent || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you willing to be responsible for your work?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.is_willing_to_be_responsible ||
                          '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you willing to take advice and obey instructions?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_iron_clothes || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you willing to work overtime?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_work_overtime || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you willing to work for 2 years on contract?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_work_2_years_on_contract || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you comfortable to be left alone in the house?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_be_left_alone_in_house || '-'}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-2/3">Are you prepared to work with Non-Muslim employer?</div>
                      <div className="w-1/3">
                        {currentIGWService?.input_data?.can_work_with_non_muslim_employer || '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between text-xl font-bold bg-grey-200 p-3 px-4 rounded-t-md">
                  Step 5 - Uploaded Documents
                  <Button
                    className="ml-3 flex items-center"
                    type="success"
                    size="default"
                    loading={isLoading}
                    onClick={exportImageToZip}
                    icon={<BiDownload className="mr-2" size={16} />}
                  >
                    Export Files
                  </Button>
                </div>
                <div className="col-span-12 bg-white">
                  <div className="p-4">
                    <div className="flex flex-col mb-4">
                      <p className="text-lg font-bold mb-1">Your Identity Card (KTP)</p>
                      <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                        {!isEmpty(currentIGWService?.input_data?.ktp_id_card_image) && (
                          <Image
                            width={300}
                            src={currentIGWService?.input_data?.ktp_id_card_image}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col mb-4">
                      <p className="text-lg font-bold mb-1">Your Birth Certificate</p>
                      <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                        {!isEmpty(currentIGWService?.input_data?.birth_certificate_image) && (
                          <Image
                            width={300}
                            src={currentIGWService?.input_data?.birth_certificate_image}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col mb-4">
                      <p className="text-lg font-bold mb-1">Your Family Card (KK)</p>
                      <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                        {!isEmpty(currentIGWService?.input_data?.kk_family_card_image) && (
                          <Image
                            width={300}
                            src={currentIGWService?.input_data?.kk_family_card_image}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col mb-4">
                      <p className="text-lg font-bold mb-1">Your Photo</p>
                      <div className="p-1 bg-white rounded-lg width-fit border border-grey-300 mb-1">
                        {!isEmpty(currentIGWService?.input_data?.photo) && (
                          <Image
                            width={300}
                            src={currentIGWService?.input_data?.photo}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 text-lg font-bold row-span-full p-2 bg-white rounded-lg height-fit">
                {!isEmpty(currentIGWService?.input_data?.photo) && (
                  <img src={currentIGWService?.input_data?.photo} alt="img-igw" className="img-igw" />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default IGWServicesDetails;
