import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { Table, Card } from 'antd';
import { getTransactionStatus } from '../../utils/get-transaction-status';
import TableUniversal from '../../components/table';
import { listCustomerTransactionAction } from '../../store/actions/customerActions';
import { useSelector } from 'react-redux';
import TransactionDetails from '../customers/transaction-details';
import { useParams } from 'react-router-dom';
import { dateFormat } from '../../utils/dateFormat';
import { useTranslation } from 'react-i18next';

function RecentWalletTransactionsTable() {
  const { t } = useTranslation();
  const { userId } = useParams();
  let title = `Recent Wallet Transactions`;
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Wallet',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('wallet_details.section_title'),
      url: '/wallet-details',
      last: false,
    },
    {
      home: false,
      title: 'User Details',
      url: `/wallet-details/${userId}`,
      last: false,
    },
    { home: false, title: 'Recent Transaction', url: '/', last: true },
  ];
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    user_id: userId,
  };
  const menuFilter = [
    {
      key: 1,
      value: 'Payment Success',
    },
    {
      key: 2,
      value: 'Payment Pending',
    },
    {
      key: 3,
      value: 'Payment Failed',
    },
  ];
  const { transactionList, transactionListLoading, transactionListError } =
    useSelector((state) => state.customers);
  const { Column } = Table;
  useEffect(() => {
    console.log('use Effect');
    return () => {
      console.log('use Effect return');
    };
  }, []);

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <TableUniversal
          initialTableParams={initialTableParams}
          menuFilter={menuFilter}
          menuFilterBy="status"
          dispatchAction={listCustomerTransactionAction}
          data={transactionList}
          loading={transactionListLoading}
          error={transactionListError}
        >
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            align="left"
            render={(text, record) => record?.sender_user?.name}
          />
          <Column
            title="Details"
            dataIndex="mobile"
            key="mobile"
            render={(text, record) => record?.sender_user?.mobile}
          />
          <Column
            title="Date & Time"
            dataIndex="created_at"
            key="created_at"
            sorter={true}
            render={(text, record) => dateFormat(record?.created_at)}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            sorter={true}
            render={(text, record) => record?.amount}
          />
          <Column
            className="text-sm px-2 py-3"
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => getTransactionStatus(record?.status)}
            width={160}
          />
          <Column
            className="text-sm px-2 py-3"
            title="Actions"
            dataIndex="action"
            key="action"
            fixed="right"
            render={(text, record) => (
              <TransactionDetails transactionId={record?.id} />
            )}
            width={120}
          />
        </TableUniversal>
      </Card>
    </>
  );
}

export default RecentWalletTransactionsTable;
